import loginScript from "./temp/loginScript"
import genericFollowUpScript from "./temp/genericFollowUpScript"
import homepageLandingScript from "./temp/homepageLandingScript"
import lookCollectionLandingScript from "./temp/lookCollectionLandingScript"
import lookLandingScript from "./temp/lookLandingScript"
import hairStatePreCheck from "./temp/hairStatePreCheck"
import hairStateQuiz from "./temp/hairStateQuiz"
import hairStateQuizScreen from "./temp/hairStateQuizScreen"
import chatLaunchScript from "./temp/chatLaunchScript"
import tutorialBookingScript from "./temp/tutorialBookingScript"

// TODO: Where are the scripts kept?
// How are they organised?
const scripts = {
  loginScript,
  homepageLandingScript,
  lookCollectionLandingScript,
  lookLandingScript,
  genericFollowUpScript,
  hairStatePreCheck,
  hairStateQuiz,
  hairStateQuizScreen,
  chatLaunchScript,
  tutorialBookingScript
}

// If this is a user input type element, give the message the user 'self' as the author ID.
// TODO: consider any one of the myriad ways there must be to improve this
const userInputMessageTypes = [
  "ResponseMessage",
  "IdentityMessage",
  "ComponentMessage"
]

const getScriptById = async function(id) {
  // TODO: Where are the scripts kept?
  // How are they organised?
  if (scripts[id] && scripts[id].dynamicScript) {
    // TODO: We need to pre-build the hair quiz, so we might as well do it just before it's required.
    // but this seems pretty clumsy
    await scripts[id].buildScript()
  }
  return scripts[id]
}

const getHairQuizScript = async function() {
  const hairState = await scripts.hairStateQuizScreen.buildScript()
  return { script: scripts.hairStateQuizScreen, hairState }
}

const createBotMessage = function(msg, id) {
  return {
    media: null,
    ...(userInputMessageTypes.includes(msg.messageType)
      ? {
          author: "Me",
          authorId: id.buuid
        }
      : {
          author: "BleachBot",
          authorId: "bleach_bot"
        }),
    created: new Date().toISOString(),
    id: Date.now().toString(),
    ...msg,
    text: personaliseMessageContent(msg.text, id)
  }
}

const personaliseMessageContent = function(msg, id) {
  // TODO: This is where to create personalised content, by creating 'tags' that we can use in the script to refer to known values, such as the user's name.
  let entities = [{ name: "[* NAME *]", value: id.name }]
  entities.forEach(el => {
    msg = msg && msg.replace(el.name, el.value)
  })
  return msg
}

const createLookConfigFlow = function(lookContent) {
  console.log(lookContent)
  // for each stage of the look
  // create a selection of products which will become part of the payload of an ActionElement (eg { stage_name, products })
  // associate narrative message(s) to each stage (including interpretation of the )
  // generate the script from the list of messages
}

export default {
  scripts,
  getScriptById,
  getHairQuizScript,
  createBotMessage,
  createLookConfigFlow
}
