import axios from "axios"

let config = {}
const defaultConfig = {
  ignore: ["product", "productBundle", "productGiftCard"],
  // TODO: Figure out a better way of capturing the 'core' page elements (eg Nav and Site Config)
  // currently this is just a lookup of all content models that might feature in those places
  coreModels: [
    "siteConfig",
    "menu",
    "menuItem",
    "footer",
    "siteBanner",
    "uiElement",
    "page"
  ]
}

export default {
  init: userConfig => {
    // console.log("*** CONTENTCACHE init ***")
    setUserConfig(userConfig)
    window.BleachContent__Cache = []
    window.BleachContent__CoreCache = []
  },

  reset: (hardReset = false) => {
    window.BleachContent__DataReady = false
    window.BleachContent__Cache = []
    if (hardReset) window.BleachContent__CoreCache = []
  },

  addFullContentData: content => {
    window.BleachContent__FullContentData = content
  },

  addRoutes: routes => {
    window.BleachContent__Routes = routes
  },

  pushContent: entry => {
    if (
      window.BleachContent__Cache &&
      entry &&
      !config.ignore.includes(entry.contentType)
    ) {
      window.BleachContent__Cache.push(entry)
      if (config.coreModels.includes(entry.contentType))
        window.BleachContent__CoreCache.push(entry)
    }
  },

  submitContent: async (/* path */) => {
    const contentArray = Array.from(
      new Set([
        ...window.BleachContent__Cache,
        ...window.BleachContent__CoreCache
      ])
    )
    let contentData = {}
    // map the aggregated content back to the same structure as the store holds it
    await contentArray.forEach(entry => {
      if (contentData[entry.contentType] == undefined)
        contentData[entry.contentType] = {}
      contentData[entry.contentType][entry.id] = entry
    })
    window.BleachContent__Data = contentData
    window.BleachContent__DataReady = true
  },

  getContent: path => {
    // console.log("getContent for", path)
    return axios
      .get(
        `${process.env.VUE_APP_BASE_API_URL}/snapshots/${encodeURIComponent(
          path
        )}`,
        {
          headers: {
            Accept: "application/json"
          }
        }
      )
      .then(res => {
        return res.data.data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

function setUserConfig(userConfig) {
  // The 'ignore' list is additive - declared types get added to the existing list
  config.ignore = Array.from(
    new Set(
      userConfig && Array.isArray(userConfig.ignore)
        ? defaultConfig.ignore.concat(userConfig.ignore)
        : defaultConfig.ignore
    )
  )
  // The 'coreModels' list is overriden by user settings
  config.coreModels =
    (userConfig && userConfig.coreModels) || defaultConfig.coreModels
}
